import Vue from 'vue'
import axios from 'axios'
import {
    ACCESS_TOKEN
} from '@/store/mutation-types'
import {baseUrl} from '@/config/env.dev'
import message from 'ant-design-vue/es/message'
import router from "../router";

const service = axios.create({
    baseURL: baseUrl.upload,
    method: 'post',
    timeout: 60000
})

// 错误处理
const err = (error) => {
    console.log('error')
    return Promise.reject(error)
}

// 请求拦截器
service.interceptors.request.use(config => {
    return config
}, err)

// 响应拦截器
service.interceptors.response.use(response => {
    if ((response.data.status && response.data.status !== 0) || response.data.error) {
        handleError(response.data)
        return Promise.reject(response.data)
    }
    return response.data
}, err)

export function upload(url, file, withToken = true) {
    if (withToken) {
        url = appendToken(url)
    }
    return new Promise((resolve, reject) => {
        console.log(file);

        const formData = new FormData();
        formData.append('file', file, file.name);

        console.log(formData)

        service({
            baseURL: baseUrl.main,
            url: url,
            method: 'POST',
            timeout: 60000,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

function appendToken(url) {
    const token = Vue.ls.get(ACCESS_TOKEN)
    // const token = 'a7412f4e5e0f4006b6be25e4331dc4f3'; // 黄振杰
    // const token = '6e46c059451e406d96a184fb2471f31d'; // 消费者：
    if (url.indexOf('?') < 0) {
        url += '?access_token=' + token
    } else {
        url += '&access_token=' + token
    }
    return url
}

function handleError(res) {
    if (res.status === 1004) {
        res.message = '会话已过期，请重新登录'
        router.replace({
            path: '/login'
        }).then(r => {
        });
    }
    message.error(res.message)
}
