<template>
  <div id="app" style="height: 100vh; overflow: auto;">

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

    <div>
      <!-- 任务明细-->
      <div class="white_box" style="margin-top: 16px; padding-top: 12px;">
        <div style="display: flex">
          <div style="flex: 5; font-size: 14px; color: #222222; font-weight: bold; line-height: 14px;">
            {{ this.data.taskReservationConfirmUser.taskReservationConfirm.name }}
          </div>
          <div :class="handleStateClass(data.taskReservationConfirmUser.confirmState)"
               style="flex: 2; text-align: end;">
            {{ handleConfirmState(data.taskReservationConfirmUser.confirmState) }}
          </div>
        </div>

        <div style="display: flex; color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
          {{ '待确认人: ' }}
          <div style="margin-left: 4px;" v-if="this.data.taskReservationConfirmUser.businessCustomerUser.name">
            {{ this.data.taskReservationConfirmUser.businessCustomerUser.name }}
          </div>
        </div>

        <div style="display: flex; color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
          {{ '开始时间: ' }}
          <div style="margin-left: 4px;" v-if="this.data.taskReservationConfirmUser.taskReservationConfirm.startTime">
            {{ this.data.taskReservationConfirmUser.taskReservationConfirm.startTime | moment('yyyy-MM-DD HH:mm:ss') }}
          </div>
        </div>

        <div style="display: flex; color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
          {{ '截止时间: ' }}
          <div style="margin-left: 4px;" v-if="this.data.taskReservationConfirmUser.taskReservationConfirm.endTime">
            {{ this.data.taskReservationConfirmUser.taskReservationConfirm.endTime | moment('yyyy-MM-DD HH:mm:ss') }}
          </div>
        </div>

        <div style="display: flex; color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
          {{ '确认范围: ' }}
          <div style="margin-left: 4px;"
               v-if="this.data.taskReservationConfirmUser.taskReservationConfirm.reservationStartTime">{{
              this.data.taskReservationConfirmUser.taskReservationConfirm.reservationStartTime | moment('yyyy-MM-DD')
            }}
          </div>
          <div style="margin-left: 4px;"
               v-if="this.data.taskReservationConfirmUser.taskReservationConfirm.reservationStartTime">{{ ' 至 ' }}
          </div>
          <div style="margin-left: 4px;"
               v-if="this.data.taskReservationConfirmUser.taskReservationConfirm.reservationEndTime">
            {{ this.data.taskReservationConfirmUser.taskReservationConfirm.reservationEndTime | moment('yyyy-MM-DD') }}
          </div>
        </div>

      </div>

      <!-- 选择异议明细-->
      <div class="white_box" style="margin-top: 12px; padding-top: 12px;">
        <div style="font-size: 14px; color: #222222; font-weight: bold; margin-bottom: 12px;">选择异议明细</div>

        <div v-for="(productBundle, i) in productBundleList" :key="i">
          <div style="margin-top: 16px" v-if="i != 0"></div>
          <div
              style="font-size: 14px; line-height: 14px; color: #222222; font-weight: bold; margin-bottom: 12px; border-left: 3px #34AC40 solid; padding-left: 6px;">
            {{ productBundle.name }}
          </div>
          <div style="display: flex; margin-bottom: 8px; background-color: #F2F2F2; color: #222222; padding: 2px 0;">
            <div style="flex: 1; text-align: center;">选择</div>
            <div style="flex: 2; text-align: center;">日期</div>
            <div style="flex: 2; text-align: center;">类型</div>
          </div>

          <div v-for="(item, index) in productBundleMap.get(productBundle.id)" :key="index">
            <div style="display: flex; margin-top: 8px;">
              <van-checkbox v-model="productBundleCheckedMap.get(productBundle.id)[index]"
                            style="flex: 1; justify-content: center; align-self: center;"
                            @click="onClickSelect()" v-if="item.confirmState === 'UNCONFIRMED'"></van-checkbox>
              <van-checkbox v-model="productBundleCheckedMap.get(productBundle.id)[index]"
                            style="flex: 1; justify-content: center; align-self: center;" disabled
                            v-if="item.confirmState !== 'UNCONFIRMED'"></van-checkbox>
              <div style="flex: 2; text-align: center; align-self: center; font-size: 14px; line-height: 14px;">
                {{ item.day }}
              </div>
              <div
                  :class="item.type === 'ENROLL' ? 'reservation_record_type_enroll' : 'reservation_record_type_stop'"
                  style="flex:2;">
                {{
                  handleReservationType(item.type)
                }}
              </div>
            </div>
            <div v-if="index < data.reservationRecordCutList.length - 1"
                 style="background-color: #f1f1f1; height: 1px; margin-top: 8px"></div>
          </div>
        </div>

        <van-empty class="custom-image"
                   v-if="!productBundleList || productBundleList.length===0"
                   description="无报停餐记录"/>

      </div>

    </div>

    <!-- 异议说明-->
    <div class="white_box" style="margin-top: 12px; padding-top: 12px; margin-bottom: 76px;">
      <div style="font-size: 14px; color: #222222; font-weight: bold; margin-bottom: 12px;">异议说明</div>

      <van-field
          v-model="dissentRemark"
          rows="5"
          autosize
          type="textarea"
          placeholder="请输入异议说明，并附上沟通文件"
          style="border: 1px #F2F2F2 solid"
      />

      <div style="font-size: 14px; color: #222222; margin-bottom: 12px; margin-top: 12px;">附件（请上传小于1M图片）</div>

      <van-uploader v-model="fileList" :max-size="isOverSize" @oversize="onOverSize" :max-count="1"
                    :after-read="afterRead" :before-read="beforeRead"/>

    </div>

    <!-- 按钮 -->
    <div class="white_box3 mgt_3vw" style="position: absolute; bottom: 0px; width: 100%; padding-bottom: 12px;">
      <div class="white_box3_in pdb_0">
        <div class="tree_btn2">
          <ul class="clear">
            <li style="width: 50%; padding: 0 8px;">
              <van-checkbox v-model="checkedAll" style="height: 40px; padding: 6px 0px; margin: 8px auto;"
                            v-if="checkedAllShow"
                            @click="onClickSelectAll">全选
              </van-checkbox>
              <div v-if="!checkedAllShow" style="width: 100%; height: 1px;"></div>
            </li>
            <li style="width: 50%; padding: 0 8px;">
              <button class="btn_confirm" @click="onClickConfirm">提交</button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <van-dialog v-model="showResult" confirm-button-color="#222222" @confirm="onResultConfirm">
      <div class="feedback_in" style="padding: 24px 0 24px 0">
        <div style="text-align: -webkit-center;">
          <div style="height: 100px; width: 100px;" :style="{backgroundImage:`url(${resultIconUrl})`}"/>
        </div>
        <div style="color: #222222; margin-top: 4px;">{{ resultState }}</div>
        <div style="color: #222222; margin-top: 4px;">{{ resultTitle }}</div>
        <div v-if="resultCreatedBy !== ''" style="color: #A1A1A1; font-size: 14px; line-height: 14px; margin-top: 8px">
          {{
            '提交人: ' + resultCreatedBy
          }}
        </div>
        <div style="color: #A1A1A1; font-size: 14px; line-height: 14px; margin-top: 8px">{{
            '提交时间: ' + resultCreatedTime
          }}
        </div>
      </div>
    </van-dialog>

  </div>
</template>

<script>
import moment from "moment/moment";
import {post} from "../utils/http";
import {upload} from "../utils/httpFileUploader";
import Vue from "vue";
import {ACCESS_CustomerUser} from "../store/mutation-types";
import {Dialog, Toast} from "vant";

export default {
  name: "reservation_record_confirm_dissent",
  data() {
    return {
      id: null,
      userId: null,
      loading: false,
      cantSelected: true,
      data: {
        taskReservationConfirmUser: {
          businessCustomerUser: {},
          taskReservationConfirm: {
            startTime: null
          }
        },
        taskReservationConfirmUserId: null,
        reservationRecordCutList: [],
        taskReservationDissentCutList: [],
      },
      productBundleList: [],
      checkedList: [],
      checkedAll: false,
      checkedAllShow: false,
      showResult: false,
      resultIconUrl: '../assets/images/ico_success2.svg',
      resultState: '',
      resultTitle: '',
      resultCreatedBy: '',
      resultCreatedTime: '',
      dissentRemark: '',

      productBundleMap: new Map(),
      productBundleCheckedMap: new Map(),

      fileList: [],
    }
  },
  mounted() {
    this.currentCustomerUser = Vue.ls.get(ACCESS_CustomerUser);
    this.id = this.$route.query.id;
    this.userId = this.$route.query.userId;

    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      post('api/business/v2/taskReservationConfirm/detail/read', {
        taskReservationConfirmUserId: this.userId,
        taskReservationConfirmId: this.id
      }).then(res => {
        if (res.data.taskReservationConfirmUser.confirmState !== 'UNCONFIRMED' &&
            res.data.taskReservationConfirmUser.confirmState !== 'DISSENT_SOLVED') {
          this.loading = false;
          Toast('任务状态异常');
          this.$router.push('/reservationRecordConfirmList');
          window.history.pushState('forward', null, '');
          window.history.forward(1);
          return;
        }
        this.data = res.data;

        // 处理停餐数据
        const map = new Map();
        let productBundleList = []
        this.data.reservationRecordCutList.forEach(item => {
          productBundleList.push({
            id: item.productBundleId,
            name: item.productBundleName
          });
        });

        this.productBundleList = productBundleList.filter(item => !map.has(item.id) && map.set(item.id, 1));
        const productBundleMap = new Map();
        const productBundleCheckedMap = new Map();
        this.productBundleList.forEach(item => {
          const reservationRecordList = [];
          const reservationRecordCheckedList = [];
          let checkedAllShow = true;
          this.data.reservationRecordCutList.forEach(reservationRecordCut => {
            if (reservationRecordCut.productBundleId === item.id) {
              reservationRecordList.push(reservationRecordCut);
              if (reservationRecordCut.confirmState === 'UNCONFIRMED'
                  || reservationRecordCut.confirmState === 'CONFIRMED') {
                reservationRecordCheckedList.push(false);
              } else {
                checkedAllShow = false;

                // 异议状态处理
                if (reservationRecordCut.state === 'CONFIRMED') {
                  reservationRecordCheckedList.push(false);
                } else {
                  reservationRecordCheckedList.push(true);
                }
              }
            }
          });

          this.checkedAllShow = checkedAllShow;

          productBundleMap.set(item.id, reservationRecordList);
          productBundleCheckedMap.set(item.id, reservationRecordCheckedList);
        });

        this.productBundleMap = productBundleMap;
        this.productBundleCheckedMap = productBundleCheckedMap;

        this.loading = false;
      }).catch(err => {
        this.loading = false;
      });
    },
    onClickConfirm() {
      let hadSelected = false;
      this.productBundleList.forEach(productBundle => {
        const key = productBundle.id;
        for (let i = 0; i < this.productBundleCheckedMap.get(key).length; i++) {
          if (this.productBundleCheckedMap.get(key)[i]) {
            hadSelected = true;
          }
        }
      });
      if (this.productBundleList && this.productBundleList.length > 0 && !hadSelected) {
        Toast('请选择明细');
        return;
      }

      if (!this.dissentRemark) {
        Toast('请在“异议说明”处填写您的意见，以便学校食堂为您核查处理哦');
        return;
      }

      // console.log(this.fileList)
      // if (!this.fileList || this.fileList.length <= 0) {
      //   Toast('请在“异议说明”处上传附件，以便学校食堂为您核查处理哦');
      //   return;
      // }

      Dialog.confirm({
        message: '确认提交异议？'
      }).then(() => {
        this.requestDissent();
      }).catch(err => {
      })
    },
    onClickSelect() {
      let isCheckedAll = true;

      this.productBundleList.forEach(productBundle => {
        const key = productBundle.id;
        for (let i = 0; i < this.productBundleCheckedMap.get(key).length; i++) {
          if (!this.productBundleCheckedMap.get(key)[i]) {
            isCheckedAll = false;
          }
        }
      });

      this.checkedAll = isCheckedAll;
      this.$forceUpdate();
    },
    onClickSelectAll() {
      this.productBundleList.forEach(productBundle => {
        const key = productBundle.id;
        for (let i = 0; i < this.productBundleCheckedMap.get(key).length; i++) {
          this.productBundleCheckedMap.get(key)[i] = this.checkedAll;
        }
      });
      this.$forceUpdate();
    },
    onResultConfirm() {
      this.$router.back(-1);
    },

    requestDissent() {
      let reservationRecordIdsList = [];
      let noReservationRecordDays = [];

      this.productBundleList.forEach(productBundle => {
        const key = productBundle.id;
        for (let i = 0; i < this.productBundleCheckedMap.get(key).length; i++) {
          if (this.productBundleCheckedMap.get(key)[i]) {
            let reservationRecord = this.productBundleMap.get(key)[i];
            if (reservationRecord.type === 'STOP') {
              reservationRecordIdsList.push(reservationRecord.reservationRecordId);
            } else {
              noReservationRecordDays.push({
                day: reservationRecord.day,
                productBundleId: reservationRecord.productBundleId
              })
            }
          }
        }
      });

      let dissentAttachmentUrl = (this.fileList && this.fileList.length > 0) ? this.fileList[0].url : '';

      let params = {
        businessCustomerUserId: this.data.taskReservationConfirmUser.businessCustomerUserId,
        taskReservationConfirmUserId: this.userId,
        dissentRemark: this.dissentRemark,
        reservationRecordIdsList: reservationRecordIdsList,
        noReservationRecordDays: noReservationRecordDays,
        dissentAttachmentUrl: dissentAttachmentUrl
      }

      this.loading = true;

      post('api/business/v2/taskReservationConfirm/user/dissent', params).then(res => {
        this.loading = false;
        this.showResult = true;
        this.resultIconUrl = require('../assets/images/ico_success2.svg');
        this.resultState = '异议提交成功';
        this.resultTitle = '请耐心等待平台处理哦~';
        this.resultCreatedBy = res.data.createdByName;
        this.resultCreatedTime = moment(res.data.createdTime).format('YYYY-MM-DD HH:mm:ss');
      }).catch(err => {
        this.loading = false;
        this.showResult = true;
        this.resultIconUrl = require('../assets/images/ico_fail2.svg');
        this.resultState = '异议提交失败';
        this.resultTitle = err.message;
        this.resultCreatedBy = '';
        this.resultCreatedTime = moment(err.timestamp).format('YYYY-MM-DD HH:mm:ss');
      });
    },

    handleReservationType(type) {
      switch (type) {
        case 'STOP':
          return '已停餐';
        case 'ENROLL':
          return '已用餐';
        default:
          return '-';
      }
    },
    handleConfirmState(confirmState) {
      switch (confirmState) {
        case 'CONFIRMED':
          return '已确认';
        case 'UNCONFIRMED':
          return '待确认';
        case 'DISSENT_PENDING':
          return '异议待处理';
        case 'DISSENT_SOLVED':
          return '异议已处理';
        default:
          return '-';
      }
    },
    handleDetailState(confirmState) {
      switch (confirmState) {
        case 'CONFIRMED':
          return '已确认';
        case 'PENDING':
          return '待处理';
        case 'SOLVED':
          return '已处理';
        case 'REJECTED':
          return '异议已拒绝';

        default:
          return '-';
      }
    },
    handleStateClass(confirmState) {
      switch (confirmState) {
        case 'CONFIRMED':
          return 'state_normal';
        case 'UNCONFIRMED':
          return 'state_normal';
        case 'DISSENT_PENDING':
          return 'state_normal_red';
        case 'DISSENT_SOLVED':
          return 'state_normal_red';

        case 'PENDING':
          return 'state_normal_red';
        case 'SOLVED':
          return 'state_normal_red';
        case 'REJECTED':
          return 'state_normal_red';
        default:
          return '';
      }
    },

    onOverSize(file) {
      Toast('请上传小于1M图片');
    },
    isOverSize(file) {
      // const maxSize = file.type === 'image/jpeg' ? 500 * 1024 : 1000 * 1024;

      const maxSize = 1000 * 1024;
      return file.size >= maxSize;
    },
    beforeRead(file) {
      if (!file.type.includes('image/')) {
        Toast('请上传图片');
        return false;
      }
      return true;
    },
    afterRead(file) {
      this.fileList = [];
      this.fileList.push({
        url: '',
        status: 'uploading',
        message: '上传中...',
      },)

      // 此时可以自行将文件上传至服务器
      upload('/api/business/file/018594a2b6e4003a40289fec85948a16/upload/file', file.file).then(res => {
        console.log(res);

        this.fileList[0].url = res.data.url;
        this.fileList[0].status = 'done';

      }).catch(err => {
        Toast(err.message)
        this.fileList[0].status = 'failed';
        this.fileList[0].message = '上传失败';

      }).finally(() => {
      })

    },
  }
}
</script>

<style scoped>
.white_box_1 {
  width: 93vw;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1pt 2.67pt #ededed;
  overflow: hidden;
  margin-top: 3vw;
  padding: 12px;
}

.btn_confirm {
  width: 100%;
  padding: 6px 26px;
  height: 40px;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 8px auto;
  border: none;
  background: #34AC40;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
}

.btn_confirm_line {
  width: 100%;
  padding: 6px 26px;
  height: 40px;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 8px auto;
  border: none;
  color: #ff6666;
  background-color: rgba(255, 102, 102, 0.2);
  border-radius: 2px;
  font-size: 13px;
}

.state_normal {
  flex: 2;
  text-align: center;
  align-self: center;
  font-size: 14px;
  line-height: 14px;
}

.state_normal_red {
  flex: 2;
  text-align: center;
  align-self: center;
  font-size: 14px;
  line-height: 14px;
  color: #ff6666;
}

.state_normal_green {
  flex: 2;
  text-align: center;
  align-self: center;
  font-size: 14px;
  line-height: 14px;
  color: #34AC40;
}

</style>